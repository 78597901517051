/*
Forms
*/
button,
input[type="number"],
input[type="interger"],
input[type="text"] {
  @apply transition;
}

.input:focus {
  @apply border-primary transition outline-2 outline-offset-0 outline-primary/30;
}

.input::placeholder {
  @apply text-gray-100 text-opacity-70;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[disabled] {
  border: none;
}

input[type="number"]:disabled {
  opacity: 0.1;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input-ghost {
  @apply bg-transparent border-0 outline-0 focus:outline-0 focus:border-0;
}

/* Labels */
.form-control input:focus + .label-placeholder {
  @apply hidden;
}

/* Buttons */
.btn {
  @apply !gap-0 !px-3 !py-2 text-gray-100;
}

.btn-ob {
  @apply !gap-0 !px-3 !py-2 text-gray-100 hover:bg-[#cc003a] hover:border-none;
}

.btn-outline {
  @apply !gap-0 !px-3 !py-2 text-gray-300 hover:bg-dk-gray-700 hover:text-gray-100  bg-dk-gray-800 border-none;

}

.btn-base200 {
  @apply bg-base-200 border-base-200;
}

.btn-md {
  @apply px-3 py-2 min-h-0 max-h-[36px] rounded-lg flex items-center;
}

.btn-md.btn-circle {
  @apply w-12;
}

.btn-ghost:hover {
  @apply bg-base-200;
}

.btn-disabled,
.btn-disabled:hover,
.btn[disabled],
.btn[disabled]:hover,
.btn-md[disabled],
.btn-md[disabled]:hover  {
  @apply text-gray-500 bg-dk-gray-800;
}


