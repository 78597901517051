.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(10, 10, 10, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader {
    border-top-color: transparent;
    -webkit-animation: spinner 1s linear infinite;
    animation: spinner 1s linear infinite;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.Toastify__toast-theme--dark {
    @apply !bg-gray-900 !text-slate-300 
}

.Toastify__toast-body {
    @apply !my-0
}

.Toastify__toast {
    @apply !mb-24 w-[50vw] mx-auto text-base shadow-2xl hover:shadow-none transform-gpu translate-y-0 hover:translate-y-1 !rounded-lg relative transition-all duration-500 ease-in-out;
}

.Toastify__toast-icon {
    width: 16px !important;
    height: 16px !important;
}


.slick-initialized .slick-slide {
    padding-right: 16px;
}

#new-feeds .slick-initialized .slick-slide {
    padding-right: 0px;
}
#new-feeds .slick-slider .slick-track {
    display: flex;
    align-items: center;
}
#mobile .slick-slider .slick-track {
    margin-left: 0;
    margin-right: 0;
}

.button__bar {
    display: inline-block;
    text-align: center;
    height: 0px;
    margin-top: 8px;
  }
  
.button__bar li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    margin: 0 4px;
    padding: 0;
  }
  
.button__bar li button {
    border: none;
    background: #D9D9D9;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 4px;
    width: 4px;
    border-radius: 4px;
  }
  
.button__bar li.slick-active button {
  background-color: rgba(255, 17, 86, 1);
  }
  
.bg-gradient {
    background: linear-gradient(135deg, #293344 0%, #19212E 100%);
}

.preview_pre_post{
    width: calc(100% - 48px);
  }

/* width */
::-webkit-scrollbar {
    width: 1px;
}
 
/* Track */
::-webkit-scrollbar-track {
    background:transparent;
    border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
}
 
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: transparent;
}
