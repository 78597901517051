:root {
  --ft-brand: #E355B1;
  --ft-brand-rgb: 227, 85, 177;
  --ft-accent: #6AF7FE;
  --ft-accent-rgb: 106, 247, 254;
}

html, body {
  height: 100%;
  width: 100%;
}

body {
  background-size: cover;
  position: relative;
}


.login{
  background: url(../assets/images/page-bg.svg) no-repeat top center #020617;
  background-size: cover;
}

.login::after {
  @apply relative md:absolute;
}

.btn-shadow {
  box-shadow: 0 0 4px var(--ft-brand);
}

.btn-shadow:hover {
  box-shadow: 0 0 8px var(--ft-brand);
}

/* Bullet list */
.bullet-list li {
  padding-left: 1.875rem;
  position: relative;
}

.bullet-list li::before {
  background: var(--ft-brand);
  content: "";
  display: block;
  border-radius: 50%;
  height: 10px;
  position: absolute;
  left: 0;
  top: 8px;
  width: 10px;
}


/* Hero */
#ft-hero {
}

.ft-btn {
  color: #020617;
  border-radius: 12px;
  display: inline-block;
  font-weight: 500;
  padding: 0 .5rem;
  position: relative;
}

.ft-btn::before,
.ft-btn::after {
  background: #40F8FF;
  border-radius: 12px;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50%;
}

.ft-btn::after {
  background: #EC53B0;
  right: 0;
  left: auto;
}

.ft-btn span {
  background: #fff;
  border-radius: 12px;
  display: inline-block;
  padding: 1rem 1.25rem;
  position: relative;
  z-index: 20;
}

.video-bg::after {
  background: linear-gradient(to top, #020617, rgba(0, 0, 0, 0.0));
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 160px;
}

/* Hero */

.btn-main{
}

.btn-main::before,
.btn-main::after {
  background: #06b6d4;
  background: linear-gradient(to right, #22d3ee, #06b6d4);
  border-radius: 32px 0 0 32px;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 30%;
  transition: width 0.25s ease-in-out;
}

.btn-main::after {
  background: #ec4899;
  background: linear-gradient(to right, #ec4899, #f472b6);
  border-radius: 0 32px 32px 0;
  left: auto;
  right: 0;
  width: 70%;
}

.btn-main:hover::before {
  width: 70%;
}

.btn-main:hover::after {
  width: 30%;
}

.btn-main img {
  transition: transform 1s cubic-bezier(.075, .82, .165, 1);
}

.btn-main:hover img {
  transform: rotate(1turn);
}

#privy-dialog #email-input {
  display: none;
}

#privy-dialog div div div div div label{
  display: none;
} 


#privy-dialog div div :nth-child(3) div :nth-child(1) button{
  display: none;
}

#privy-dialog div div :nth-child(3) div :nth-child(3) {
  display: none;
}

/* #privy-dialog div div :nth-child(3) div .cTNXBG {
  display: none;
} */

/* #privy-dialog div div div div div h2, p {
  display: none;
} */

#privy-dialog div div button{
  margin-top: 16px;
}
