#root {
  --toastify-toast-min-height: 12px;
  --toastify-toast-max-height: 46px;
  --toastify-z-index: 9999;
  @apply bg-base-300 h-full w-full;

}

:root {
  --swiper-navigation-size: 40px !important;
}

html {
  @apply scroll-smooth w-full h-full bg-dk-gray-900;
}

body {
  @apply antialiased text-gray-100 overflow-hidden;
}

body,
html,
:root {
  @apply bg-base-300 h-full w-full;
}

body.has-Modal {
  @apply overflow-hidden;
}

#__next {
  @apply w-full h-full;
}

/*
Tables
*/
table {
}

.th {
  @apply w-auto py-3 px-4 font-bold text-slate-500 bg-base-content/10 tracking-wide;
}

.td {
  @apply w-auto py-4 px-4 whitespace-nowrap;
}

.tr {
  @apply relative;
}

thead .tr {
  @apply relative overflow-hidden;
  .th:first-child {
    @apply md:rounded-l-box;
  }
  .th:last-child {
    @apply md:rounded-r-box;
  }
}

/* .tr::before {
  content: " ";
  @apply bg-base-200 bg-opacity-40 backdrop-blur w-full h-full absolute inset-0 bg-no-repeat bg-cover -z-50 rounded-box;
  #253143
} */

.th,
.thead .th {
  @apply text-sm font-medium uppercase;
}

tbody .tr {
  @apply transition border-b border-base-content/10;
}

tbody .tr:hover {
  @apply bg-base-100/50 shadow;
}

tbody .tr-hilite {
  @apply bg-base-100 shadow;
}

/* tbody .tr:nth-child(even) {
  @apply bg-base-100;
} */

/* tbody .tr:hover {
  @apply bg-accent bg-opacity-30;
} */

/* .tr td:first-child,
.tr th:first-child {
  @apply rounded-tl-box rounded-bl-box;
}

.tr td:last-child {
  @apply rounded-tr-box rounded-br-box;
} */

/*
Misc
*/
:focus-visible {
}

.bg-custom {
  background: linear-gradient(180deg, rgba(64, 248, 255, 0.10) 0%, rgba(2, 6, 23, 0.10) 35%)
}
