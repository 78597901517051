.sendbird-theme--dark {
font-family: 'PT Root UI', sans-serif !important;
}

.fix-width {
  width: 100% !important;
}
.sendbird-channel-list {
  @apply h-full w-full !important;
}

.sendbird-channel-list__header {
  display: none !important;
}

#chat-detail-other .sendbird-conversation {
  @apply  bg-base-300 absolute pt-1 !important;
  background: transparent
}

#chat-detail-other .sendbird-conversation__footer { 
  padding-bottom: 12px !important
}

#chat-detail .sendbird-conversation {
  @apply  bg-base-300 relative pt-1 flex-1 justify-between !important;
  background: transparent;
}

#chat-detail .sendbird-conversation__footer { 
  padding-bottom: 12px !important
}


.sendbird-theme--dark .sendbird-conversation {
  @apply border-none fixed top-0 bottom-0 left-0 right-0 z-20 bg-base-300 !important;
}
.sendbird-theme--dark .sendbird-button--primary {
  @apply rounded-lg bg-secondary border-secondary !important;
}
.sendbird-theme--dark .sendbird-button--secondary {
  @apply rounded-lg border-dk-gray-800  !important;
}

.sendbird-theme--dark .sendbird-message-input .sendbird-message-input--textarea {
  @apply rounded-lg border border-dk-gray-800 bg-transparent !important;
}

.sendbird-theme--dark .sendbird-dropdown__reaction-bar {
  @apply z-[9999] !important;
}

.sendbird-message-content__middle__body-container .sendbird-message-content__middle__message-item-body.outgoing {
  @apply text-base text-gray-100 !important;
}

.sendbird-theme--dark .sendbird-text-message-item-body.incoming {
  @apply text-base text-gray-300 p-0 !important;
}

.sendbird-theme--dark .sendbird-avatar .sendbird-avatar-img {
  @apply w-8 h-8 !important;
}

.sendbird-label--caption-2 {
  @apply text-sm font-medium !important;
}

.sendbird-thumbnail-message-item-body {
  @apply w-[230px] h-[212px]  !important;
}

.sendbird-image-renderer .sendbird-image-renderer__image {
  @apply max-w-[230px] max-h-[212px] !important;
}

.sendbird-thumbnail-message-item-body, .sendbird-thumbnail-message-item-body__thumbnail{
  @apply min-w-0 max-w-[230px] max-h-[212px] !important;
}

.sendbird-image-renderer__image {
  @apply min-w-0 !important;
}

.sendbird-thumbnail-message-item-body {
  @apply min-w-0 !important;
}

.sendbird-fileviewer {
  @apply bg-base-300 w-screen !important;
}



.sendbird-fileviewer__header__right__actions__download, .sendbird-fileviewer__header__right__actions__delete, .sendbird-fileviewer__header__right__actions__close {
  @apply w-8 !important;

}

.sendbird-fileviewer__header__right {
  @apply justify-center !important;
}

.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__filename {
  @apply hidden !important;
}

.sendbird-theme--dark .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right .sendbird-fileviewer__header__right__actions__close {
border: none !important;
}

.sendbird-message-content.incoming .sendbird-message-content__middle {
  @apply bg-dk-gray-800 hover:bg-dk-gray-800 rounded-lg py-2 px-3 gap-1  !important;
}

.sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__sender-name {
  @apply ml-0 mb-0 !important;
}

.sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__body-container .sendbird-message-content__middle__body-container__created-at {
 display: none !important;
}

.sendbird-message-content.outgoing .sendbird-message-content__middle .sendbird-message-content__middle__body-container .sendbird-message-content__middle__body-container__created-at {
 display: none !important;
}

.sendbird-emoji-reactions {
  @apply p-0 pt-1 border-none !important;
}
.sendbird-emoji-reactions.outgoing {
  @apply px-2 pb-1 !important;
} 

.sendbird-theme--dark .sendbird-conversation__scroll-bottom-button {
  @apply bg-gray-50 shadow-sm !important;
}

.sendbird-message-input-wrapper--voice-message .sendbird-message-input-wrapper__message-input, .sendbird-message-input-wrapper .sendbird-message-input-wrapper__message-input {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.sendbird-modal {
  z-index: 30
}
.sendbird-modal__content {
  @apply w-[320px] max-w-md rounded-lg !important
}
.sendbird-label--h-1 {
@apply font-medium text-lg !important
}
.sendbird-modal__footer {
  @apply mt-4 !important
}

.sendbird-dropdown__menu {
  border-radius: 8px !important;
}
