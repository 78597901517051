@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

.fadein {
  animation: fadein 40ms linear;
}

@keyframes fadeinheight {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 100;
    height: 50px;
  }
}

@keyframes fadeinout {
  0% {
    opacity: 100;
  }
  25% {
    opacity: 50;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 50;
  }
  100% {
    opacity: 100;
  }
}

.fadeinout {
  animation: fadeinout 40ms ease;
}

@keyframes flash {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

.flash {
  animation: flash infinite 1s linear;
}


@keyframes expanded-width {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.expanded-width {
  animation: expanded-width 40ms ease;
}

@keyframes collapsed-width {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

@keyframes slidedown-caret {
  0% {
    top: 0;
    bottom: 0;
  }
  50% {
    top: -2px;
    bottom: auto;
  }
  80% {
    top: auto;
    bottom: -1px;
  }
  100% {
    top: 0;
    bottom: 0;
  }
}

@keyframes floating {
  0% {
    transform: translate3d(0, -20%, 0) rotate(0deg) scale(0.9);
    opacity: 0.1;
  }
  50% {
    transform: translate3d(0, 0, 0) rotate(90deg) scale(1.3);
    opacity: 0.4;
  }
  100% {
    transform: translate3d(0, -20%, 0) rotate(0deg) scale(0.9);
    opacity: 0.1;
  }
}

@keyframes color-change {
  0% {
    background: #a78bfa;
    color: #4c1d95;
  }
  50% {
    background: #facc15;
    color: #713f12;
  }
  100% {
    background: #a78bfa;
    color: #4c1d95;
  }
}

.color-change {
  animation: color-change infinite 4s ease;
}

@keyframes caret-up {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  50% {
    transform: translateY(-20%);
    opacity: 0.1;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.caret-up {
  animation: caret-up 4 0.8s forwards ease-out;
  /* animation-iteration-count: 4; */
}

@keyframes caret-down {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  50% {
    transform: translateY(20%);
    opacity: 0.1;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.caret-down {
  animation: caret-down 4 0.8s forwards;
  /* animation-iteration-count: 4; */
}

@keyframes progress-in-button {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.progress-in-button-clear {
  animation: progress-in-button 3s linear;
}
