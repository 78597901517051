
@keyframes Gradient {
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}
@-moz-keyframes Gradient {
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}
@-webkit-keyframes Gradient {
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

.loadingAnimation {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    background: linear-gradient(
            90deg,
            hsla(0, 0%, 74.5%, 0.2) 25%,
            hsla(0, 0%, 50.6%, 0.24) 37%,
            hsla(0, 0%, 74.5%, 0.2) 63%
    );
    -webkit-animation: Gradient 1.4s ease infinite;
    -moz-animation: Gradient 1.4s ease infinite;
    animation: Gradient 1.4s ease infinite;
    background-size: 400% 100%;
    background-position: 100% 50%;
}
